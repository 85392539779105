/*COLORS*/
$blumine: #20558B;
$white: #FFF;
$black: #000;
$green-pea: #236430;
$mine-shaft: #313131;
$cod-gray: #121212;
$bay-leaf: #81B191;
$gray: #D9D9D9;
$silver: #CCCCCC;
$transparent: transparent;
$regular: 300;
$semi-bold: 600;
$bold: 700;

.map-search-container {
  display: inline-block;
  position: relative;
  height: auto;
  width: 245px;
  background-color: white;
  border: 1px solid black;
  grid-row-start: 1;
  vertical-align: top;
}

.accordion {
    display: flex;
    align-items: center;
    background-color: $bay-leaf;
    color: $white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    border-radius: 0;
    text-align: left;
    outline: none;
    font-size: 14px;
    font-weight: $bold;
    transition: 0.4s;
  }

  svg {
    margin: 0 7px 0 0;
  }

  .active, .accordion:hover {
    background-color: $bay-leaf;
  }

  .panel {
    // padding: 20px;
    display: none;
    background-color: white;
    overflow: hidden;
    // width: 340px;
  }

  .map-layer {
    display: block;
    width: 100%;
  }