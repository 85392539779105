.landuse-select-buttons-container {
    display: flex;
    flex-wrap: wrap;
    background: inherit;


    .landuse-button-container:before {
        content: "";
        position: absolute;
        background: inherit;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
        filter: blur(10px);
        margin: -20px;
    }

    .landuse-button-container {
        min-width: 210px;
        height: 230px;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        z-index: 1;
        background: inherit;
        overflow: hidden;
        background: rgba(34, 99, 55, 0.27);
        border: 7px solid #236430;
        position: relative;
        color: white;
        margin: 30px;
        font-size: 22px;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        backdrop-filter: blur(5px);

        svg {
            height: 70px;
            width: 70px;
        }

        p {
            color: black;
        }
        .studycount{
            margin: 0px;
            font-size: 18px;
        }
    }
}

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
    .landuse-select-buttons-container{
        .landuse-button-container {
            cursor: pointer;
            height: 350px;
            width: 325px;

            svg {
                width: 250px;
                height: 125px;
            }

            p, .studycount{
                font-size: 35px;
            }
        }
    }
}
