.ytick, .xtick{
    text{
        font-size: 12px !important;
        text-overflow: wrap;
    }
}

.xtitle{
    font-size: 12px !important;
}

.instance-click-icon{
    cursor: pointer;
    text-align: right;
    svg{
        height: 30px;
        width: 30px;
    }
    svg:hover{
        background-color: #BFD1E3;
        // padding: 2px;
    }
}

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
    .ytick, .xtick{
        text{
            font-size: 20px !important;
        }
    }
    .xtitle{
        font-size: 20px !important;
    }
}