.HomePage-Container{
    background-image: url('../../images/sheep.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    color: white;
    background-attachment: fixed;
    background-position-x: center;
}

#welcome-title-container{
    // margin-right: auto;
    // margin-left: 500px;
    padding-top: 70px;
    padding-left: 30px;

    h1{
        font-family: "Merriweather", sans-serif;
        font-size: 40px;
        color: #FFF;
        // padding-top: 70px;
        // padding-left: 50px;
        margin-top: 0;
        text-shadow: 0 0 10px rgba(0, 0, 0, 255);
    }

    h3{
        text-align: left;
        // padding-left: 50px;
        text-shadow: 0 0 5px rgba(0, 0, 0, 255);
    }
}


.welcome-container{
    height: 700px;
    margin-left: 300px;
    margin-left: 300px;
}


.footer-container{
    background: #313131;
    color: #FFF;
    padding: 20px 40px;
    margin-top: 200px;
}
.footer-container > h3{
    text-align: left;
}

.footer-text{
    display: flex;
    width: 70%;
    /* font-size: 15px; */
}


.versionInfo {
    font-size: 10pt;
    color: darkgray;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    text-align: center;
} 

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
    #welcome-title-container{
        padding-top: 150px;
    
        h1{
            font-size: 60px;
        }
    
        h3{
            font-size: 35px;
        }
    }
}