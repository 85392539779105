.interpretations{
    // margin-left: 20px;
    // margin-right: 20px;
    // border: #BFD1E3 3px solid;
    // padding: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    padding-left: 20px;
    margin-top: 5px;
}

.interpretations-text{
    padding-left: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #707070;
    text-decoration: none;
    font-weight: 700;
}