.ChartWrapper {
    height: 100%;
    width: 100%;
}
.title{
    font-size: 20px;
    margin: 10px 20px;
    font-family: 'Roboto', sans-serif;
}
.bold{
    font-weight: 700;
    cursor: pointer;
}

#chord-chart-question{
    cursor: pointer;
    svg{
        height: 30px;
        width: 30px;
    }
    svg:hover{
        background-color: #BFD1E3;
        cursor: pointer;
        // padding: 2px;
    }
}

.violin-tooltip-1{
    margin-right: 10px;
    margin-left: 10px;
    border: 2px solid #BFD1E3;
    margin-bottom: 0px;
    padding: 10px;
}

.violin-filter-tooltip{
    display: flex;
    cursor: pointer;
    sup{
        svg{
            height: 20px;
            width: 20px;
        }
    }
    sup:hover{
        background-color: #BFD1E3;
        // padding: 2px;
    }
}

.filter-violin-data{
    display: flex;
    flex-direction: column;

    div{
      display: flex;
      flex-direction: row-reverse;
      align-items: baseline;
      justify-content: flex-end;

      label{
        margin-bottom: 0px;
        align-items: baseline;
      }
    }
  }

.violin-filter-tooltip-text{
    font-size: 10px;
    border: 2px solid #BFD1E3;
    padding: 10px;
}

.chord-chart-tooltip-container{
    border: 2px solid #BFD1E3;
    p{
        font-size: 10px;
        padding-right: 10px;
        padding-left: 10px;
        text-align: left;
    }
}

.violin-tooltip{
    font-size: 12px;
    border: 2px solid #BFD1E3;
    text-align: left;
}

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
    .title{
        font-size: 30px;
    }
}