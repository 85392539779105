  .split-pane-col {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .split-pane-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }
  
  .split-pane-top,
  .split-pane-right,
  .split-pane-left {
    flex: 1;
    overflow: hidden;
  }
  
  .split-pane-bottom{
    flex: 1;
    overflow: auto; 
  }

  .split-pane-right {
    // background-color: #ccc;
    background-size: 100% 1.2em;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  
  // .split-pane-top,
  // .split-pane-bottom {
  //   // background-color: #ccc;
  //   // text-align: left;
  //   // padding: 2%;
  // }
  
  .separator-col {
    // border: 3px solid #BFD1E3;
    min-width: 9px;
    max-width: 9px;
    min-height: 100%;
    max-height: 100%;
    background-color: white;
    cursor: col-resize;
  }

  .separator-col_handle {
    min-width: 5px;
    max-width: 5px;
    min-height: calc(100% - 40px);
    margin: 20px 2px;
    background-color: #BFD1E3;
  }
  
  .separator-row {
    // border: 1px solid #BFD1E3;
    min-width: 100%;
    max-width: 100%;
    min-height: 9px;
    max-height: 9px;
    background-color: white;
    cursor: row-resize;
  }

  .separator-row_handle {
    min-height: 5px;
    max-height: 5px;
    min-width: calc(100% - 40px);
    margin: 2px 20px;
    background-color: #BFD1E3;
  }

  #clear-practice-button{
    cursor: pointer;
    text-decoration: none;
    padding: 3px 9px;
    background-color: #20558A;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    height: 40px;
  }

  .clear-filter{
    // margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
  }

  // .filter-violin-data{
  //   display: flex;
  //   flex-direction: column;

  //   div{
  //     display: flex;
  //     flex-direction: row-reverse;
  //     align-items: baseline;
  //     justify-content: flex-end;

  //     label{
  //       margin-bottom: 0px;
  //     }
  //   }
  // }

.slider-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
  .filter-violin-data{
    div{
      label{
        font-size: 30px;
      }
      input{
        height: 20px;
        width: 20px;
      }
    }
  }
}