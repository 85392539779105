.map-chart-outer-container {
  max-height: 955px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // flex: 1 1 auto;
}

.chart-container{
  max-height: 550px;
  min-height: 550px;
  // max-height: 1000px;
  // min-height: 1000px;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-top: 2px solid #BFD1E3;
  border-bottom: 2px solid #BFD1E3;
}

.land-cover-button-container{
  height: calc(100% - 225px);
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.map-container {
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 62px calc(100% - 62px);
}

.land-cover-button-container > button{
    height: 25px;
    width: 70px;
    text-align: center;
    margin-bottom: 10px;
}
h3{
    text-align: center;
    margin-bottom: 0px;
}
.filter-search{
  // background: rgba(255, 255, 255, 0.81);
  /* border: 22px solid rgba(255, 255, 255, 0.81); */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 50px;
  height: 50px;
  min-height: 50px;
  /* box-sizing: border-box; */
}

// .clear-filter {
//   height: 55px;
//   flex: 0 0 auto;
// }

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    color: yellow
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    background-color: red;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: green;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.filter-search-container{
  // background-color: #CCCCCC;
  display: flex;
  flex-direction: column;
  // border: 10px solid #CCCCCC;
  flex: 1 1 auto;
  height: 100%;
}

.highcharts-container{
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  margin-bottom: 20px;
}


.chart-view-buttons{
  text-align: center;
  flex: 0 0 40px;
  display: flex;
  flex-direction: column;
  height: 40px;
  /* box-sizing: border-box; */
  overflow: hidden;
  align-items: center;
}

#toggle-chord-chart-button{
  width: 50%;
  text-decoration: none;
  padding: 5px 9px;
  background-color: #20558A;
  border-radius: 10px;
  color: white;
  font-weight: 700;
  border: 1px solid #20558A;
  margin: 5px;
  font-size: 14px;
}

#toggle-chord-chart-button:hover{
  cursor: pointer;
}

.outer-highcharts-container, .outer-table-container {
    height: 100%;
    width: 100%;
    background-color: white;
    flex: 1 1 auto;
    text-align: right;
}

.outer-plotly-container{
  width: 100%;
  background-color: white;
  flex: 1 1 auto;
  text-align: right;
}

.chart-view-container {
  flex: 1 1 auto;
  /* height: 100%; */
  display: flex;
  // background-color: aqua;
  flex-direction: column;
  // padding-top: 5px;
  /* box-sizing: border-box; */
}

.table-container{
  display: flex;
  justify-content: center;
  height: calc(100% - 21px);
  background-color: white;
}
.practice-result, .study-count-result{
  border: 1px solid gray;
}
.study-count-result{
  text-align: center;
  border-left: none;
}
.practice-result{
  padding-left: 5px;
  padding-right: 5px;
  border-right: none
}
.legend{
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
}

.map-wrapper {
  height: 100%;
  grid-area: 1 / 1 / last-line / 1;
  width: 100%;
}

.map-widgets {
  vertical-align: top;
  z-index: 10;
  grid-area: 1 / 1 / 1 / 1;
  text-align: right;
  svg{
    height: 30px;
    width: 30px;
  }

  svg:hover{
    background-color: #BFD1E3;
    cursor: pointer;
    // padding: 2px;
  }
}

.map-tooltip-container{
  border: 2px solid #BFD1E3;
  margin-bottom: 5px;
  p{
    font-size: 1rem;
  }
}

#blue-bar{
  transform: rotate(.25turn)
}
#blue-bar-container > p{
  color: #333333;
  font-size: 10px;
  font-weight: bold;
  fill: #333333;
  margin-left: 35px;
}
#blue-bar-container{
  display:flex;
  align-items: center;
}
#study-sites{
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: 10px 5px;
}
.reference{
  margin-top: 5px;
  margin-bottom: 0px;
  list-style: none;
  a{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #707070;
    text-decoration: none;
    // color: #20558A;
    font-weight: 700;
    // text-decoration: underline;
  }
}

// .reference:hover{
//   background-color: #d6f5d6;
// }

#citations-header{
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: black;
  padding-left: 20px;
  margin-top: 5px;
  // margin-bottom: 5px;
}

#ref-list-body{
  margin-top: 0px;
}

.citation-button-container{
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  height: 35px;
  // margin-bottom: 20px;

  button{
    width: 200px;
    border-radius: 5%;
    height: 40px;
    margin-bottom: 10px;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    border-radius: 5%;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #707070;
    border: none;
  }
}

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
  #citations-header{
    font-size: 35px;
  }
  .reference{
    margin-top: 25px;
    a{
      font-size: 22px;
    }
  }
  .citation-button-container{
    button{
      font-size: 25px;
      height: 60px;
      width: 250px;
    }
    img{
      height: 30px;
    }
  }
  .chart-container{
    // min-height: 1200px;
    // max-height: 1200px;
    min-height: 900px;
    max-height: 900px;
  }
  .map-chart-outer-container{
    // min-height: 1200px;
    // max-height: 2000px;
    min-height: 900px;
    max-height: 900px;
  }
  #study-sites{
    font-size: 30px;
  }
  .citations-outer-container{
    margin-top: 200px;
  }
  #ref-list-body{
    margin-top: 35px;
  }
}