.land-use-dropdown{
    display: flex;
    flex-direction:column;
    align-items: baseline;
}
#dropdown-breadcrumbs{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 20px;
    // min-height: 63px;
    // max-height: 63px;
    align-items: flex-start;
    margin-top: 10px;
}
.search-bubble{
    background-color: #20558A;
    border-radius: 5px;
    width: 285px;
    text-align: left;
    margin-left: 5px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    min-width: 285px;
    min-height: 70px;
    max-height: 70px;
    box-sizing: border-box;
    p{
        color: white;
        font-weight: 700;
        font-size: 14px;
        font-family: 'Lora', serif;
    }
}
#select-header{
    margin-bottom: 10px;
    margin-top: 23px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 700;
}
label{
    font-size: 14px;
    margin-bottom: 7px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: flex-start;
}
#dropdown-container{
    display: flex;
    align-items: start;
    min-width: 1130px;
    height: 115px;
}
.arrow{
    /* font-size: 16px; */
    /* margin-right: 10px; */
    margin-left: 10px;
    margin-top: 28px;
}
.italics{
    font-style: italic;
}
.number{
    font-size: 14px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.dropdown{
    font-size: 14px;
    height: 32px;
    width: 250px;
    z-index: 150;
    display: flex;
    flex-direction: column;
}

.close{
    cursor: pointer;
}

.breadcrumb-dropdown-icon{
    // border-bottom: 1px solid #20558A;
    // color: #20558A;
    margin-left: 10px;
    svg{
        margin: 0px;
        cursor: pointer;
        color: #20558A;
        font-weight: 700;
        font-weight: 700;
        height: 18px;
        width: 18px;
    }
}

// .breadcrumb-dropdown-icon:hover{
//     background-color: #BFD1E3;
//     padding: 2px;
// }

#bc_violin_1_on:hover, #bc_violin_2_on:hover, #bc_landuse:hover, #bc_con_pract:hover{
    background-color: #BFD1E3;
    // padding: 2px;
    // border-bottom: 1px solid black
}

.popup-container{
    // width: 50%;
    border: 2px solid #bfd1e3;
    padding: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    height: 60px;
    width: 230px;
    margin-left: 0px;
    p{
        font-size: 10px;
        flex-wrap: nowrap;
        padding: 0px;
        margin: 0px;
    }
}

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
    #select-header{
        font-size: 30px;
    }
    label{
        font-size: 25px;
    }
    .dropdown{
        height: 90px;
    }
    .search-bubble{
        min-height: 130px;
        max-height: 130px;
        max-width: 500px;
        min-width: 500px;

        p{
            font-size: 25px;
        }
        .number{
            font-size: 30px;
        }
    }
    #dropdown-breadcrumbs{
        margin-bottom: 90px;
        align-items: baseline;
    }
    #dropdown-container{
        align-items: flex-start;
    }
    .arrow{
        svg{
            height: 22px;
            width: 22px;
            margin-right: 40px;
            margin-left: 50px;
        }
    }
    .close{
        margin-top: 10px;
        svg{
            height: 25px;
            width: 25px;
        }
    }
}