body {
    /* height: 100%; */
    /* width: 100%; */
    // background: cadetblue;
    display: flex;
    flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  // background: aliceblue;
  /* top: 0; */
  /* bottom: 0; */
  /* position: absolute; */
  /* left: 0; */
  /* right: 0; */
  height: 100%;
  width: 100%;
  // overflow: hidden;
  overflow-y: scroll;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.AppHeader {
  flex: 0 0 auto;
  background: silver;
  height: 100px;
  margin-top: 50px;
}

.AppContent {
  flex: 2 0 auto;
  background: #FDFDFD;
  display: flex;
  flex-direction: column;
}

.AppFooter {
  flex: 0 0 auto;
  background: silver;
  height: 100px;
}

.MainMap {
  width: 100px;
  height: 100px;
  flex: 0 0 auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  background: teal;
}

.route-container {
  height:calc(100% - 95px);
  width: 100%;
}

.app-container {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.app-container-explore {
  height: 100%;
  width: 100%;
  // this is the line that was commented out
  // overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.main-content-wrapper {
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* flex: 1 1 auto; */
  /* overflow: hidden; */
  display: flex;
  /* flex: 0 0 auto; */
}