.hide{
    display: none;
}

.split-pane-bottom-tabs{
    display: flex;
    justify-content: space-around;
    align-items: end;
}

.split-tab{
    width: 50%;
    text-align: center;
    // border: 2px solid rgb(35, 100, 48);
    margin: 5px;
    margin-top: 15px;
    height: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    justify-content: center;
    border: 2px solid #bfd1e3;

    p{
        font-size: 16px;
        font-weight: 700;
    }
}

#selected-tab{
    background-color: #BFD1E3;
    height: 35px;
    border: 2px solid rgb(35, 100, 48);
}