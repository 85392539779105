.Link-Container{
    text-align: center;
    height: 70px;
    background: linear-gradient(to right, #20558b, #236430);
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 0 0 70px;
    font-family: 'Arial';

    .title-header{
        display: flex;
        align-items: baseline;

        .logo-image {
            height: 70px;
            margin-left: 10px;
            filter: drop-shadow(0 0 0.1px white);
            cursor: pointer;
        }

        .title-text { 
            margin-left: 10px;
            text-align: left;
            white-space: nowrap;
            vertical-align: middle;
            color: white;
            cursor: pointer;
            display: flex;
            // align-items: baseline;

            .nrcs-text {
                display: inline-block;
                font-weight: bold;
                font-size: 30px;
                vertical-align: middle;
            }

            .core-text {
                display: inline-block;
                margin-left: 15px;
                text-align: left;
                font-size: 25px;
                vertical-align: middle;

                span{
                    font-size: 35px;
                    font-weight: 700;
                }
            }

            .pairing-science{
                margin-left: 30px;
                font-size: 12px;
                font-style: italic;
                font-weight: 700;
                margin-top: 40px;
            }
        }
    }

    .links-themselves{
        display: flex;
        align-items: center;
        padding-right: 10px;

        a {
            margin: 0 5px;
            padding: 5px 10px;
            border: 1px solid white;
            white-space: nowrap;
            font-size: 15px;
            color: white;
            text-decoration: none;
        }
    }

    .stone-logo{
        height: 50px;
        margin-left: 20px;
        margin-top: 10px;
    }

    .usda-logo{
        height: 45px;
    }

}
.nrcs-text{
    cursor: pointer;
}
$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
    .Link-Container {
      height: 125px;
      background-color: red;

      .stone-logo{
          height: 70px;
          margin-left: 40px;
          padding: 20px;
      }

      .title-header{
        margin-left: 20px;

        .logo-image{
            height: 115px;
        }
        
        .title-text{
            .core-text{
                font-size: 30px;
            }
            .nrcs-text{
                font-size: 55px;
            }
        }

        }

        .links-themselves{
            margin-right: 20px;
        }
        
    }
    #home-link{
        height: 60px;
        width: 200px;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
