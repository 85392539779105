.MuiButton-text{
    font-weight: 700 !important;
    text-align: left !important;
    font-size: 20px;

    // a{
    //     color: #20558A;
    // }
}

.MuiTableRow-root{
    // custom text align by row
    // td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(7){
    // .MuiTableCell-sizeMedium {
    //     display: flex;
    //     flex-direction: column;
    // }
    // th{
    //     text-align: center !important;
    //     // display: flex;
    //     // flex-direction: column;
    // }
    th{
        background-color: #808080eb;
        color: black;
        border: 2px solid white;
        border-radius: 4px;
        padding: 5px;

        svg{
            background-color: #808080eb !important;
            // color: white !important;
            display: none !important;

            path{
                background-color: #808080eb !important;
                // color: transparent !important;
            }
        }
    }

    .MuiButton-sizeMedium{
        font-weight: 700;
    }

    td{
        text-align: center !important;
    }
    
}

.MuiTable-root{
    border-collapse: separate;
}

.MuiTableCell-body{
    border: 1px solid #c1c1c1 !important;
    a{
        color: #20558A;
        font-weight: 700;
    }
}


.MuiTableCell-root{
    padding-right: 10px !important;
    padding-left: 10px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    width: 5px !important;
    // border: 1px solid gray !important;
}

.MuiTableRow-root{
    .MuiTableCell-header{
        span{
            svg{
                width: 100%;
            }
        }
    }
    .MuiTableCell-head:nth-child(3), .MuiTableCell-head:nth-child(4), .MuiTableCell-head:nth-child(5), .MuiTableCell-head:nth-child(7){
        // display: flex;
        // flex-direction: row;
        span{
            text-align: right !important;
            display: flex;
            flex-direction: row-reverse;
            button{
                padding-right: 0px !important;
                margin-right: 0px !important;
            }
        }

    }
}

// .MuiTableCell-head{
    
//     span:nth-child(1){
//         color: orange !important;
//     }
// }

.MuiButton-label{
    font-size: 15px !important;
    line-height: 1 !important;
}

.MuiSvgIcon-fontSizeSmall{
    // background-color: black;
    background-color: white;
    color: white;
    border-radius: 20px;
    height: 15px !important;
    width: 15px !important;
}

// .MuiButton-text{
//     padding: 0px !important;
// }

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
    .MuiButton-text{
        font-size: 22px !important;
    }
    .MuiTableCell-body{
        font-size: 25px !important;
    }
    .MuiTypography-h6{
        font-size: 30px !important;
    }
    .MuiToolbar-gutters{
        p{
            font-size: 30px !important;
        }
    }
    .MuiSvgIcon-root{
        height: 40px !important;
        width: 40px !important;
    }
    .MuiListItem-button{
        font-size: 30px !important;
    }
    .MuiSelect-selectMenu{
        font-size: 30px !important;
    }
    .Mui-TablePagination-selectIcon{
        top: 0 !important;
    }
    .MuiTablePagination-selectRoot{
        width: 100px !important;
    }
    // .MuiSelect-nativeInput{
    //     font-size: 30px !important;    
    // }
}