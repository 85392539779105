#mapDiv{
    height: calc(100% - 40px);
    width: 100%;
}

.abstract-bold{
    font-weight: 700;
}

.abstract-text{
    font-family: 'Ariel, san-sarif';
}