.icon > rect > path{
    color: #236339
}
.rangeland-icon, .pasture-icon, .forest-icon {
    height: 60px;
    width: 120px;
    filter: grayscale(100%);
}

.rangeland-icon:hover, .pasture-icon:hover, .forest-icon:hover, .cropland-icon:hover {
    filter: drop-shadow(0 0 15px rgba(255, 255,255,255));
}

.cropland-icon{
    height: 70px;
    width: 70px;
    filter: grayscale(100%);
}

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
    .rangeland-icon, .pasture-icon, .forest-icon {
        width: 250px;
        height: 125px;
    }
    .cropland-icon {
        height: 125px;
        width: 125px;
    }
    
}