.highcharts-title{
	font-family: 'Roboto', sans-serif !important;
}

.highcharts-data-label-hidden{
	opacity: 1 !important;
	text-align: left !important;
}

$breakpoint-tablet: 2000px;
@media screen and (min-width: $breakpoint-tablet) {
	.highcharts-title{
		font-size: 30px !important;
		vertical-align: sub !important;
	}
	.highcharts-label{
		text{
			font-size: 25px !important;
		}
	}
	// .xtitle{
	// 	font-size: 35px !important;
	// 	// font-size: 12px !important;
	// }
}
